import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={1} title={"Hackaviz"} description={"Troisième édition"} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`HACKAVIZ 2020`}</h1>
        <p>{`Troisième édition
L’association Toulouse Dataviz (TDV) organise son HACKAVIZ. Concours de visualisation de données en temps limité, seul ou en équipe, doté de récompenses, ouvert à un très large public.`}</p>
        <h1>{`LE PRINCIPE`}</h1>
        <p>{`Raconter une histoire avec des graphiques, à partir d’un jeu de données inédit, seul ou en équipe.
A partir du moment ou les données sont mises à disposition, les candidats ont 10 jours pour rendre leur création.`}</p>
        <ul>
          <li parentName="ul">{`Date de mise à disposition des données : 12 Mars 2020`}</li>
          <li parentName="ul">{`Date limite de remise des contributions : 22 Mars 2020`}</li>
          <li parentName="ul">{`Hackaviz party : Présentation des réalisations et remise des prix  : 26 Mars 2020`}</li>
        </ul>
        <h1>{`PRÉPARER L’HACKAVIZ`}</h1>
        <p>{`Voici des newsletters pour vous préparer à l'Hackaviz.`}</p>
        <Button link={"https://www.notion.so/toulousedataviz/News-Letter-TDV-Hackaviz-2020-1-a7e85f7175574c0494e222c89db40cd3"} display={"special"} text={"#1 Pourquoi visualiser ?"} mdxType="Button" />
        <Button link={"https://www.notion.so/News-Letter-TDV-Hackaviz-2020-2-43c548bc7c9b4df7a3404ac540bba23d"} display={"special"} text={"#2 Comment visualiser ?"} mdxType="Button" />
        <Button link={"https://www.notion.so/toulousedataviz/News-Letter-TDV-Hackaviz-2020-3-17d4167f5340487c88072aa381eca43d"} display={"special"} text={"#3 Des outils et des exemples"} mdxType="Button" />
        <Button link={"https://www.notion.so/toulousedataviz/News-Letter-TDV-Hackaviz-2020-4-fbf173c0264b4d4e9ec5e04b7494ccae"} display={"special"} text={"#4 Exemples & entrainements"} mdxType="Button" />
        <h1>{`LE JEU DE DONNÉES`}</h1>
        <p>{`Un  jeu de données `}<strong parentName="p">{`exceptionnel`}</strong>{` concernant la région Occitanie, libéré pour la première fois pour l’occasion.
Le jour du début du Hackaviz, les candidats reçoivent un lien vers une page de téléchargement contenant:`}</p>
        <ul>
          <li parentName="ul">{`les données au format xlsx, csv ou geojson,`}</li>
          <li parentName="ul">{`un dictionnaire des données expliquant la nature des observations et la définition des variables`}</li>
        </ul>
        <Button link={"/hackaviz/2020-data"} type={'internal'} text={"Télécharger les données"} mdxType="Button" />
        <h1>{`LES RÈGLES`}</h1>
        <ul>
          <li parentName="ul">{`Les candidats peuvent être des individus ou des équipes. Le nombre de personnes regroupées sous une candidature doit être précisé à l’inscription. Une fois inscrit, le candidat – ou l’équipe – s’engage à envoyer sa contribution avant la date de fin du concours.`}</li>
          <li parentName="ul">{`Les candidats s’engagent à autoriser la publication de leur contribution sur le site toulouse-dataviz.fr `}</li>
          <li parentName="ul">{`Les candidats s’engagent à être présents ou représentés ou à défaut disponible par skype lors de l’Hackaviz party du 26 mars 2020.`}</li>
          <li parentName="ul">{`Les candidats peuvent ensuite utiliser tous les outils qu’ils souhaitent pour explorer ces données et en proposer une représentation visuelle à base de graphiques.`}</li>
          <li parentName="ul">{`Les candidats peuvent effectuer tous types de calcul à partir de ce jeu de données`}</li>
          <li parentName="ul">{`Les candidats enverront leur contribution par mail avant la date limite à une adresse qui sera communiquée lors de la mise à disposition des données.`}</li>
          <li parentName="ul">{`Le format de restitution de cette analyse visuelle sera au format pdf et ne devra pas dépasser l’équivalent de 2 pages A4 ou l’équivalent de 3 captures d’écran si la réalisation est sur le web.`}</li>
          <li parentName="ul">{`Dans le cas d’une création interactive ou utilisant des services web, le candidat (ou l’équipe) enverra un mail contenant uniquement l’URL valide de la réalisation.`}</li>
          <li parentName="ul">{`Les candidats s’attacheront à ajouter des éléments contextuels pour commenter le ou les graphiques.`}</li>
          <li parentName="ul">{`Les candidats fourniront la liste des outils utilisés pour la réalisation des graphiques`}</li>
          <li parentName="ul">{`Les participants n’ont pas l’obligation d’utiliser toutes les données.`}</li>
          <li parentName="ul">{`Hormis des fonds de carte, les candidats ne sont pas autorisés à utiliser d’autres données que celles fournies.`}</li>
        </ul>
        <h1>{`ÉVALUATION`}</h1>
        <p>{`Les contributions seront jugées par un jury de spécialistes proposé par les organisateurs. Ces personnalités n’auront pas le droit de concourir à l’Hackaviz, ni de collaborer avec aucune des équipes en compétition.`}</p>
        <p>{`Les contributions seront évaluées en fonction de différents critères dont les suivants (liste non exhaustive, ordre non priorisé).`}</p>
        <ul>
          <li parentName="ul">{`Pertinence pour servir la narration/histoire abordée.`}</li>
          <li parentName="ul">{`Originalité`}</li>
          <li parentName="ul">{`Maîtrise des règles de l’art`}</li>
          <li parentName="ul">{`Concision`}</li>
        </ul>
        <p>{`Le jury de l’Hackaviz est souverain, ses décisions sont donc définitives. Le jury se réserve le droit de pénaliser les contributions qui ne respecteraient pas les règles. Les résultats seront annoncés lors de l’Hackaviz party .`}</p>
        <h1>{`HACKAVIZ PARTY`}</h1>
        <p>{`Avant la cérémonie de remise des prix, tous les participants pourront présenter leur réalisation et échanger autour de stands mis à leur disposition.
Tous les candidats seront appelés à commenter leur réalisation sous forme de “pitch rapide” lors de la remise des prix.
L’ Hackaviz party sera filmée en live pour permettre aux participants ne pouvant se déplacer de voir l’événement en direct`}</p>
        <HackavizFooter year={"2020"} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      